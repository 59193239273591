import { fixScrollBarWidth, lockScroll } from "./scroll";

export function createModal(type) {
    const modalWrap = document.createElement("div");
    const modalContainer = document.createElement("div");
    const closeBtn = document.createElement("button");

    if (type === "gallery") {
        const galleryWrap = document.createElement("div");
        const next = document.createElement("button");
        const prev = document.createElement("button");

        galleryWrap.classList.add("modal__gallery");
        next.classList.add("modal__next");
        prev.classList.add("modal__prev");

        modalContainer.appendChild(galleryWrap);
        modalContainer.appendChild(next);
        modalContainer.appendChild(prev);
    }

    modalWrap.classList.add("modal");
    modalContainer.classList.add("modal__container");
    closeBtn.classList.add("modal__close");

    document.body.appendChild(modalWrap);
    modalWrap.appendChild(modalContainer);
    modalContainer.appendChild(closeBtn);
}

// ---------- open modal ---------- //

export function openModal() {
    const modalWrap = document.querySelector(".modal");

    modalWrap.style.display = "block";
    modalWrap.classList.add("fadeIn");

    lockScroll(true);
    fixScrollBarWidth(true);
}

// ---------- close modal ---------- //

export function closeModal(modalWrap, callback) {
    modalWrap.classList.add("fadeOut");
    setTimeout(() => {
        document.body.removeChild(modalWrap);
        if (callback) callback();
        lockScroll(false);
        fixScrollBarWidth(false);
    }, 450);
}

export function iframe(selector) {
    const openers = document.querySelectorAll(selector);
    openers.forEach(opener => {
        opener.addEventListener("click", () => {
            createModal();
            const iframe = document.createElement("iframe");
            iframe.classList.add("modal__iframe");
            iframe.setAttribute("frameborder", "0");
            iframe.setAttribute(
                "allow",
                "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            );
            iframe.setAttribute("allowfullscreen", "");
            iframe.setAttribute("width", "1080");
            iframe.setAttribute("height", "720");

            const modalWrap = document.querySelector(".modal");
            const modalContainer = document.querySelector(".modal__container");
            const closeUp = document.querySelectorAll(".modal__close");

            modalContainer.classList.add("modal__iframeWrap");
            modalContainer.appendChild(iframe);
            const dataModal = opener.querySelector('[data-modal]').getAttribute("data-modal");
            if (dataModal != null) {
                console.log('opening modal');
                iframe.setAttribute("src", `https://www.youtube.com/embed/${dataModal}`);
                openModal();
            } else {
                closeModal(modalWrap);
            }
            iframe.style.height = `${iframe.offsetWidth * 0.5625}px`;

            closeUp.forEach(item => {
                item.addEventListener("click", () => {
                    closeModal(modalWrap);
                });
            });

            modalWrap.addEventListener("click", e => {
                if (e.target === modalWrap) {
                    closeModal(modalWrap);
                }
            });
        });
    });
}
