import {header} from "./modules/header";
import {openMenu} from "./modules/openMenu";
import {dropdown} from "./modules/dropdown";
import {lazyload} from "./modules/lazyload";
import {iframe} from "./modules/modal/iframe";
import {form} from "./modules/form";

import {getCLS, getFID, getLCP, getTTFB} from "web-vitals";

const loadDependencies = () => {
    setTimeout(() => {
        const scripts = document.querySelectorAll("script.lazy");
        scripts.forEach(script => {
            const src = script.dataset.src;
            script.setAttribute("src", src);
        });
    }, 20000);
};

document.addEventListener("DOMContentLoaded", () => {
    header();
    lazyload();
    openMenu();
    dropdown();
    iframe(".iframe-popup");
    form();
    loadDependencies();
    console.log("scripts loaded");
});

const scores = {
    CLS: [0.1, 0.25],
    FID: [100, 300],
    LCP: [2500, 4000],
};

const round = (n, signs = 0) => Math.round(n * 10 ** signs) / 10 ** signs;
const reportScore = (name, delta) => {
    const metrics = scores[name];
    if (metrics != null) {
        const index = scores[name].findIndex(score => delta < score);
        const score = index === -1 ? "Poor" : index === 1 ? "Need improvement" : "Good";
        console.log(`[${name}]`, score, `${round(delta, 4)}ms`);
    } else {
        console.log(`[${name}]`, `${round(delta, 4)}ms`);
    }
};

const reportAnalytics = ({name, delta, id, value}) => {
    reportScore(name, value);
    // Assumes the global `dataLayer` array exists, see:
    // https://developers.google.com/tag-manager/devguide
    if (!Array.isArray(window.dataLayer)) {
        window.dataLayer = [];
    }
    window.dataLayer.push({
        event: "web-vitals",
        event_category: "Web Vitals",
        event_action: name,
        // Google Analytics metrics must be integers, so the value is rounded.
        // For CLS the value is first multiplied by 1000 for greater precision
        // (note: increase the multiplier for greater precision if needed).
        event_value: Math.round(name === "CLS" ? delta * 1000 : delta),
        // The `id` value will be unique to the current page load. When sending
        // multiple values from the same page (e.g. for CLS), Google Analytics can
        // compute a total by grouping on this ID (note: requires `eventLabel` to
        // be a dimension in your report).
        event_label: id,
    });
};

getCLS(reportAnalytics);
getFID(reportAnalytics);
getLCP(reportAnalytics);
getTTFB(reportAnalytics);
