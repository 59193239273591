import {popupMessageForm} from "./modal/popup";
import {select} from "./select";
import emailjs from "emailjs-com";

window.ymGoal = goal => {
    console.log("yandex goal", goal);
    const yaCounter = window[`yaCounter${window.metrikaId}`];
    if (yaCounter != null) {
        yaCounter.reachGoal(goal);
    }
};

window.gaGoal = goal => {
    console.log("google goal", goal);
    //window.ga && window.ga("send", category, action, label);
    window.dataLayer &&
        window.dataLayer.push({
            event: goal,
        });
};

window.fpGoal = goal => {
    window.fbq != null && window.fbq("track", goal);
};

const completeGoal = () => {
    window.gaGoal("Form");
    window.ymGoal("message");
    window.ymGoal("OtpravkaPodatzayavky");
    window.fpGoal("Lead");
    console.log("goal updated");
};

export function form() {
    const form = document.querySelector(".form:not(.form-independent)");
    if (form == null) {
        return;
    }

    select();

    const btn = form.querySelector(".btn");
    const spinner = btn.querySelector(".spinner");
    const inputs = form.querySelectorAll("input, textarea");
    const lang = document.querySelector("html").getAttribute("lang");

    form.addEventListener("submit", async e => {
        e.preventDefault();

        const toValidate = [...new Set(Array.from(form.querySelectorAll("input[validate]")).map(input => input.name))];
        const formData = new FormData(form);
        const data = {};
        formData.forEach((value, key) => {
            data[key] = value;
        });
        // eslint-disable-next-line immutable/no-let
        let valid = true;
        toValidate.forEach(name => {
            if (data[name] == null || data[name] === "") {
                valid = false;
                console.log("invalid", name, data[name]);
                const label = form.querySelector(`.form__label_${name}`);
                if (label != null) {
                    label.classList.toggle("invalid", true);
                }
            } else {
                const label = form.querySelector(`.form__label_${name}`);
                if (label != null) {
                    label.classList.toggle("invalid", false);
                }
            }
        });

        if (valid) {
            try {
                disabled(true);
                if (
                    window.location.hostname === "mlchina.ru" &&
                    typeof data?.phone === "string" &&
                    data.phone.startsWith("1") &&
                    typeof data.name === "string" &&
                    // eslint-disable-next-line no-control-regex
                    data.name.match(/[^\u0000-\u007f]/) == null
                ) {
                    console.log("Probably spam");
                    disabled(false);
                    return;
                }
                const response = await fetch("https://api.globus.furniture/orders/newCard", {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        ...data,
                        source: window.location.hostname,
                    }),
                });
                const resData = await response.json();
                console.log(resData);
                popupMessageForm(lang === "ru" ? "Сообщение отправленно!" : "Message has been successfully sent");
                completeGoal();
                form.reset();
            } catch (e) {
                emailjs
                    .send(
                        "mail_ru",
                        "new_form_submission",
                        {
                            text: Object.keys(data)
                                .map(key => `[${key}]: ${data[key]}`)
                                .join("\n"),
                        },
                        "user_HGBG5mFMtquZlW1Mk46lK",
                    )
                    .then(console.log);
                popupMessageForm(
                    lang === "ru"
                        ? "Произошла ошибка! Пожалуйста, напишите нам на info@globus.world"
                        : "Error! Please send us message directly to info@globus-china.com",
                );
                console.log({
                    ...data,
                    source: window.location.hostname,
                });
                console.log(e);
            } finally {
                disabled(false);
            }
        } else {
            popupMessageForm(
                lang === "ru" ? "Пожалуйста, заполните все поля" : "Please fill all the fields marked with red in",
            );
        }
    });

    function disabled(d) {
        if (d) {
            // eslint-disable-next-line immutable/no-let
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].setAttribute("disabled", "disabled");
            }
            btn.setAttribute("disabled", "disabled");
            spinner.style.display = "block";
            btn.textContent = "";
            btn.appendChild(spinner);
        } else {
            // eslint-disable-next-line immutable/no-let
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].removeAttribute("disabled");
            }
            btn.removeAttribute("disabled");
            btn.textContent = lang === "ru" ? "Отправить" : "Send";
        }
    }
}
