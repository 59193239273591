export function openMenu() {
    const btn = document.querySelector(".burger");
    const menu = document.querySelector(".header__menu");

    btn.addEventListener("click", () => {
        console.log("menu button clicked");
        btn.classList.toggle("active");
        menu.classList.toggle("open");
    });
}
